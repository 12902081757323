<template>
  <div style="margin-top:16px">
    <div class="el-form-item1">
      <el-upload
        ref="my_upload"
        :class="uploadDisabled"
        :action="handleUploadUrl"
        with-credentials
        :file-list="fileList"
        list-type="picture-card"
        :data="attachmentData"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :disabled="showdisabled"
      >
        <el-icon>
          <Plus />
        </el-icon>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/runtime-core'
import { Plus } from '@element-plus/icons-vue'
export default {
  components: {
    Plus
  },
  props: {
    type: {
      type: Number
    }
  },
  emit: ['updateFrom'],
  setup(props, context) {
    const showdisabled = ref(false)
    const uploadDisabled = ref()
    const attachmentData = reactive({})
    const attachmentId = ref([])
    const fileList = ref([])
    const handleUploadUrl = ref(
      'https://java.yedeqin.com/upload/uploadPhoto'
      // 'http://127.0.0.1:8081/upload/uploadPhoto'
    )

    const handleAvatarSuccess = (res, file) => {
      attachmentId.value.push(res.data)
      // 上传超上限图片后将 uploadDisabled 变量设置为 disabled
      if (attachmentId.value.length > 15) {
        uploadDisabled.value = 'disabled'
      }
      // 操作子组件添加图片信息
      context.emit('updateFrom', JSON.stringify(attachmentId.value))
    }

    // 删除上传图片
    const handleRemove = (file, fileList) => {
      attachmentId.value.splice(
        attachmentId.value.indexOf(file.response.data),
        1
      )
      context.emit('updateFrom', JSON.stringify(attachmentId))
    }

    const my_upload = ref(null)

    // 关闭弹窗清空缓存信息
    const clearPictureInfo = () => {
      my_upload.value.clearFiles()
      uploadDisabled.value = ''
      attachmentId.value = []
    }
    return {
      showdisabled,
      uploadDisabled,
      attachmentData,
      attachmentId,
      fileList,
      handleUploadUrl,
      handleAvatarSuccess,
      handleRemove,
      my_upload,
      clearPictureInfo
    }
  }
}
</script>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
}
.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.avatar {
  width: 100px;
  height: 100px;
}
</style>
